import React, { Component } from 'react';
import $ from "jquery";

class ApiHelper {
    constructor(apiUrl) {
        this.apiUrlBase = apiUrl;
        this.uid = '';

        this.apiUrls = {
            getProjectInfo: this.apiUrlBase + '/main/load',
            estimateProduction: this.apiUrlBase + '/main/estimate-production',
            saveProjectInfo: this.apiUrlBase + '/main/save',
            saveNewCenter: this.apiUrlBase + '/main/save-center',
            createProject: this.apiUrlBase + '/main/create',
            bugReport: this.apiUrlBase + '/main/bug-report',
            getPixelColor: this.apiUrlBase + '/others/get-pixel-color',
            getDesignRequestsInfo: this.apiUrlBase + '/design-requests/info',
            startDesignRequest: this.apiUrlBase + '/design-requests/start',
            completeDesignRequest: this.apiUrlBase + '/design-requests/complete',
            reviseDesignRequest: this.apiUrlBase + '/design-requests/revise',
            unfulfillDesignRequest: this.apiUrlBase + '/design-requests/unfulfill',
            getNearmapPhotos: this.apiUrlBase + '/nearmap/get-photos',
        };
    }


    async getNearmapPhotos(uid) {
        let url = this.apiUrls.getNearmapPhotos + '/' +uid, info, result;

        try {
            result = await fetch(url);
        } finally {
            if (!result.ok) {
                let errorMessage = result.status+': '+result.statusText+"\r\n"+result.url;
                window.appComponent.bugReport(errorMessage);
            }
        }

        try {
            info = await result.json();
        } catch (e) {
            let errorMessage = e.name+': '+e.message;
            window.appComponent.bugReport(errorMessage);
        }

        return info;
    }


    async unfulfillDesignRequest(uid) {
        this.uid = uid;

        let url = this.apiUrls.unfulfillDesignRequest + '?' + new URLSearchParams({
            uid: uid
        }), info, result;

        try {
            result = await fetch(url);
        } finally {
            if (!result.ok) {
                let errorMessage = result.status+': '+result.statusText+"\r\n"+result.url;
                window.appComponent.bugReport(errorMessage);
            }
        }

        try {
            info = await result.json();
        } catch (e) {
            let errorMessage = e.name+': '+e.message;
            window.appComponent.bugReport(errorMessage);
        }

        return info;
    }


    async reviseDesignRequest(uid) {
        this.uid = uid;

        let url = this.apiUrls.reviseDesignRequest + '?' + new URLSearchParams({
            uid: uid
        }), info, result;

        try {
            result = await fetch(url);
        } finally {
            if (!result.ok) {
                let errorMessage = result.status+': '+result.statusText+"\r\n"+result.url;
                window.appComponent.bugReport(errorMessage);
            }
        }

        try {
            info = await result.json();
        } catch (e) {
            let errorMessage = e.name+': '+e.message;
            window.appComponent.bugReport(errorMessage);
        }

        return info;
    }


    async completeDesignRequest(uid) {
        this.uid = uid;

        let url = this.apiUrls.completeDesignRequest + '?' + new URLSearchParams({
            uid: uid
        }), info, result;

        try {
            result = await fetch(url);
        } finally {
            if (!result.ok) {
                let errorMessage = result.status+': '+result.statusText+"\r\n"+result.url;
                window.appComponent.bugReport(errorMessage);
            }
        }

        try {
            info = await result.json();
        } catch (e) {
            let errorMessage = e.name+': '+e.message;
            window.appComponent.bugReport(errorMessage);
        }

        return info;
    }


    async startDesignRequest(uid) {
        this.uid = uid;

        let url = this.apiUrls.startDesignRequest + '?' + new URLSearchParams({
            uid: uid
        }), info, result;

        try {
            result = await fetch(url);
        } finally {
            if (!result.ok) {
                let errorMessage = result.status+': '+result.statusText+"\r\n"+result.url;
                window.appComponent.bugReport(errorMessage);
            }
        }

        try {
            info = await result.json();
        } catch (e) {
            let errorMessage = e.name+': '+e.message;
            window.appComponent.bugReport(errorMessage);
        }

        return info;
    }


    async getDesignRequestsInfo(uid) {
        this.uid = uid;

        let url = this.apiUrls.getDesignRequestsInfo + '?' + new URLSearchParams({
            uid: uid
        }), info, result;

        try {
            result = await fetch(url);
        } finally {
            if (!result.ok) {
                let errorMessage = result.status+': '+result.statusText+"\r\n"+result.url;
                window.appComponent.bugReport(errorMessage);
            }
        }

        try {
            info = await result.json();
        } catch (e) {
            let errorMessage = e.name+': '+e.message;
            window.appComponent.bugReport(errorMessage);
        }

        return info;
    }


    async getPixelColor(url, x, y) {
        let result = false;

        result = $.ajax({
            url: this.apiUrls.getPixelColor,
            method: 'POST',
            data: JSON.stringify({
                url: url,
                x: x,
                y: y
            }),
            context: this,
            async: true,
            dataType: 'json',
            success: function (response) {
                result = response;
            },
            error: function (a, b, c) {

            }
        });

        return result;
    }


    async getProjectInfo(uid) {
        this.uid = uid;

        let url = this.apiUrls.getProjectInfo + '?' + new URLSearchParams({
            uid: uid
        }), info, result;

        try {
            result = await fetch(url);
        } finally {
            if (!result.ok) {
                let errorMessage = result.status+': '+result.statusText+"\r\n"+result.url;
                window.appComponent.bugReport(errorMessage);
            }
        }

        try {
            info = await result.json();
        } catch (e) {
            let errorMessage = e.name+': '+e.message;
            window.appComponent.bugReport(errorMessage);
        }

        return info;
    }


    async saveProjectInfo(dataFull) {
        let url = this.apiUrls.saveProjectInfo + '?' + new URLSearchParams({
            uid: this.uid
        });

        $.ajax({
            url: url,
            method: 'POST',
            data: {
                data: JSON.stringify(dataFull)
            },
            context: this,
            async: false,
            success: function (response) {
                //console.log(response);
                //window.appComponent.populateProjectFromPVWatts(response);
            },
            error: function (a, b, c) {
                window.appComponent.bugReport(a.responseText);
            }
        });

        return true;
    }


    async saveNewCenter(center) {
        let url = this.apiUrls.saveNewCenter + '?' + new URLSearchParams({
            uid: this.uid
        });

        $.ajax({
            url: url,
            method: 'POST',
            data: {lat: center.lat, lng: center.lng},
            context: this,
            async: false,
            success: function (response) {
                window.location.reload();
            },
            error: function (a, b, c) {
                window.appComponent.bugReport(a.responseText);
            }
        });

        return true;
    }


    async estimateProduction(dataFull, isDRComplete) {
        let url = this.apiUrls.estimateProduction + '?' + new URLSearchParams({
            uid: this.uid
        });

        //console.log(JSON.stringify(dataFull.project.solarOffset));

        $.ajax({
            url: url,
            method: 'POST',
            data: {
                data: JSON.stringify(dataFull)
            },
            context: this,
            async: false,
            success: function (response) {
                //console.log(response);
                dataFull.project.solarOffset = Math.round(response.data.total_production / dataFull.project.tac * 100);
                window.appComponent.populateProjectFromPVWatts(response);
                dataFull['is_dr_complete'] = isDRComplete;
                this.saveProjectInfo(dataFull);
            },
            error: function (a, b, c) {
                window.appComponent.bugReport(a.responseText);
            }
        });

        return true;
    }


    createProject(uid, installer_id, address, lat, lng, sid, tac, paid) {
        let url = this.apiUrls.createProject;

        const dataFull = {
            uid: uid,
            installer_id: installer_id,
            address: address,
            lat: lat,
            lng: lng,
            sid: sid,
            tac: tac,
            paid: paid
        };

        let result = null;
        $.ajax({
            url: url,
            method: 'POST',
            data: {
                data: JSON.stringify(dataFull)
            },
            context: this,
            async: false,
            success: function (response) {
                result = response;
                if (result.status == false) {
                    window.appComponent.bugReport(result.description);
                }
            },
            error: function (a, b, c) {
                window.appComponent.bugReport(a.responseText);
            }
        });

        return result;
    }


    async bugReport(errorMessage) {
        let url = this.apiUrls.bugReport + '?' + new URLSearchParams({
            url: process.env.REACT_APP_SLACK_BUGREPORT_URL,
            access_token: process.env.REACT_APP_SLACK_BUGREPORT_ACCESS_TOKEN,
            errorMessage: errorMessage
        });

        let result = await fetch(url);
    }
}

export default ApiHelper;