import React, { Component } from 'react';

import TopPanel from './Components/TopPanel/TopPanel';
import OnmapPanel from "./Components/General/OnmapPanel";
import Map from './Components/Map/Map';
import ApiHelper from './helpers/ApiHelper';
import Notifier from './helpers/Notifier';
import Error from "./Components/General/Error";
import PSAlert from "./Components/General/PSAlert";
import Blank from "./Components/General/Blank";
import BottomPanel from "./Components/BottomPanel/BottomPanel";
import {ToastContainer} from "react-toastify";

/* global google */

class App extends Component {

    /**
     * @type {Map|null}
     */
    mapComponent = null;

    constructor(props) {
        super(props);

        let preventShadingPopup = false,
            isShadingRestricted = this.findGetParameter('shadingrestrict');
        if (isShadingRestricted && isShadingRestricted === 'true') {
            preventShadingPopup = true;
        }

        this.state = {
            hasError: false,
            gmAPILoaded: false,
            panelTypesAvailable: [],
            project: {},
            onmapProjectSummary: {},
            isCalculating: false,
            alertOpen: false,
            shadingAlertOpen: false,
            onmapPanelIdle: false,
            preventShadingPopup: preventShadingPopup,
            requestDesignStatus: null,
            nearmapPhoto: null,
            nearmapBBox: null
        };

        this.doRouting();

        this.apiHelper = new ApiHelper(this.props.apiUrl);

        this.notifier = new Notifier();

        window.appComponent = this;

        if (this.route === 'create') {
            this.createNewProject();
            return;
        }

        this.topPanelChanged = this.topPanelChanged.bind(this);
        this.onDataChanged = this.onDataChanged.bind(this);
        this.onCalculate = this.onCalculate.bind(this);
        this.onShadeAnalysis = this.onShadeAnalysis.bind(this);
        this.onSolarOffsetChange = this.onSolarOffsetChange.bind(this);
        this.onAlertOpenChanged = this.onAlertOpenChanged.bind(this);
        this.onShadingAlertOpenChanged = this.onShadingAlertOpenChanged.bind(this);
        this.onShadingAlertValueAccepted = this.onShadingAlertValueAccepted.bind(this);
        this.onAlertAccept = this.onAlertAccept.bind(this);
        this.geocodeChangedAddress = this.geocodeChangedAddress.bind(this);
        this.updateCenter = this.updateCenter.bind(this);
        this.onNearmapPhotoChanged = this.onNearmapPhotoChanged.bind(this);
    }


    onShadingAlertValueAccepted(shadingValue) {
        this.mapComponent.saveShadingValue(shadingValue);

        this.setState({
            shadingAlertOpen: false
        });
    }


    onShadingAlertOpenChanged(newOpen) {
        this.setState({
            shadingAlertOpen: newOpen
        });

        if (newOpen === false) {
            this.mapComponent.disableAll();
        }
    }


    onAlertOpenChanged(newOpen) {
        this.setState({
            alertOpen: newOpen
        });
    }


    onAlertAccept() {
        let project = this.state.project;
        project['address'] = this.findGetParameter('address');
        project['data'] = [];

        if (this.mapComponent.state.solarPanelGroups != {}) {
            for (const [id, solarPanel] of Object.entries(this.mapComponent.state.solarPanelGroups)) {
                this.mapComponent.state.solarPanelGroups[id].selfDelete();
            }
        }
        this.repopulateFromSolarPanels();

        if (window.appComponent.state.gmAPILoaded) {
            this.geocodeChangedAddress(project);
        } else {
            window.addEventListener('google-maps-loaded', () => this.geocodeChangedAddress(project));
        }
    }


    geocodeChangedAddress(newProjectState) {
        let that = this;

        this.geolocationReady = this.geolocateAddress(newProjectState.address);
        this.geolocationReady.then(function(coords) {
            if (coords.lat) {newProjectState.lat = coords.lat;}
            if (coords.lng) {newProjectState.lng = coords.lng;}

            that.setState({
                alertOpen: false,
                project: newProjectState,
            });

            window.appComponent.mapComponent.goToLatLng(newProjectState.lat, newProjectState.lng);
        });
    }


    onSolarOffsetChange(newSolarOffset) {
        let project = this.state.project;
        project['solarOffset'] = newSolarOffset;
        this.setState({
            project: project
        });
    }


    doRouting() {
        this.route = 'default';
        let pathParts;

        const viewPath = /projects\/([a-zA-Z0-9-]+)/g;
        const editPath = /projects\/([a-zA-Z0-9-]+)\/edit/g;
        const createPath = /projects\/([a-zA-Z0-9-]+)\/create/g;
        const requestDesignPath = /request\/projects\/([a-zA-Z0-9-]+)\/edit/g;
        if (pathParts = editPath.exec(window.location.pathname)) {
            this.route = 'edit';
            this.projectUID = pathParts[1];

            if (pathParts = requestDesignPath.exec(window.location.pathname)) {
                this.routeSubtype = 'request-design';
            }
        } else if (pathParts = createPath.exec(window.location.pathname)) {
            this.route = 'create';
            this.projectUID = pathParts[1];
        } else if (pathParts = viewPath.exec(window.location.pathname)) {
            this.route = 'view';
            this.projectUID = pathParts[1];
        }
    }


    createNewProject() {
        const createPath = /projects\/([a-zA-Z0-9-]+)\/create(\/?)(.*)/g,
              pathParts = createPath.exec(window.location.pathname),
              uid = decodeURIComponent(pathParts[1]),
              address = (decodeURIComponent(pathParts[3]))?decodeURIComponent(pathParts[3]):this.findGetParameter('address'),
              lat = this.findGetParameter('lat'),
              lng = this.findGetParameter('lng'),
              sid = this.findGetParameter('sid'),
              tac = this.findGetParameter('tac'),
              paid = this.findGetParameter('paid'),
              installer_id = this.findGetParameter('installer_id'),
              shadingRestrict = this.findGetParameter('shadingrestrict');

        const createResult = this.apiHelper.createProject(uid, installer_id, address, lat, lng, sid, tac, paid);

        let newLocation = '/projects/'+uid+'/edit';
        if (shadingRestrict && shadingRestrict === 'true') {
            newLocation += '?shadingrestrict=true';
        }

        window.location = newLocation;
        if (createResult.status === true) {
            window.location = newLocation;
        } else {
            console.log(createResult);
        }
    }


    updateCenter(center) {
        let project = this.state.project;
        project.lat = center.lat;
        project.lng = center.lng;

        this.setState({
            project: project
        });
    }


    findGetParameter(parameterName) {
        let result = null,
            tmp = [];
        window.location.search
            .substr(1)
            .split("&")
            .forEach(function (item) {
              tmp = item.split("=");
              if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
            });
        return result;
    }


    topPanelChanged(panelInfo) {
        let project = this.state.project;

        for (const [key, value] of Object.entries(panelInfo)) {
            project[key] = value;
        }

        this.setState({
            project: project
        });
    }


    onDataChanged(data) {
        let project = this.state.project;
        project.data = data;

        this.setState({
            project: project
        });
    }


    onCalculate(e, optionalCallback, isDRComplete) {
        window.appComponent.notifier.warning();
        this.mapComponent.disableAllGroups();
        this.mapComponent.disableAllSetbacks();

        setTimeout((that) => {
            let data = that.mapComponent.calculate();
            data['project'] = that.state.project;
            data['nearmap_photo'] = that.state.nearmapPhoto;

            that.apiHelper.estimateProduction(data, isDRComplete).then(() => {
                let customNotificationOptions = {};
                if (optionalCallback !== undefined) {
                    customNotificationOptions = {
                        //"timeOut": "1000",
                    };
                }
                that.notifier.success('System Calculated!', '', customNotificationOptions);

                document.querySelectorAll('.export').forEach((el) => {
                    el.removeAttribute('disabled');
                });

                this.setState({
                    isCalculating: false
                }, optionalCallback);
            });
        }, 400, this);
    }


    populateProjectFromPVWatts(rawResponse) {
        this.mapComponent.populateSolarPanelsFromPVWatts(rawResponse);
        this.repopulateFromSolarPanels(this.mapComponent.state.solarPanelGroups);
    }


    repopulateFromSolarPanels(solarPanelGroups) {
        let onmapProjectSummary = this.state.onmapProjectSummary,
            data = [];

        if (solarPanelGroups) {
            for (const [id, solarPanel] of Object.entries(solarPanelGroups)) {
                data.push(solarPanel.save());
            }
        }

        onmapProjectSummary.tac = this.state.project.tac;
        onmapProjectSummary.data = data;
        onmapProjectSummary.panelType = this.state.panelTypesAvailable[this.state.project.panelType];

        this.setState({
            onmapProjectSummary: onmapProjectSummary
        });
    }


    onShadeAnalysis() {
        this.mapComponent.toggleShadeAnalysis();
    }


    componentDidMount() {
        if (this.route == 'view' || this.route == 'edit') {
            this.loadProjectInfo();

            this.includeGoogleMapsAPI();
        } else {
            this.includeGoogleMapsAPI();
        }
    }


    loadProjectInfo() {
        let that = this;

        this.projectInfoReady = new Promise((resolve, reject) => {
            that.apiHelper.getProjectInfo(that.projectUID).then((projectInfo) => {
                projectInfo.project['pvwatts'] = {
                    providerAccountId: ((projectInfo.project.paid)?(projectInfo.project.paid):(that.projectUID))
                };
                if (that.findGetParameter('tac')) {
                    projectInfo.project['tac'] = that.findGetParameter('tac');
                }
                if (that.findGetParameter('paid')) {
                    projectInfo.project['paid'] = that.findGetParameter('paid');
                }
                if (that.findGetParameter('address') && (projectInfo.project.address != that.findGetParameter('address'))) {
                    that.setState({
                        alertOpen: true
                    }, function() {
                        //that.mapComponent.hideLoader();
                    });
                }

                if (projectInfo.project.address && !projectInfo.project.lat && !projectInfo.project.lng) {
                    //console.log('Address should be geocoded');
                    that.initGeocodingForAddress(projectInfo, resolve);
                } else {
                    that.setState({
                        project: projectInfo.project,
                        panelTypesAvailable: projectInfo.panelTypesAvailable,
                        invertersAvailable: projectInfo.invertersAvailable,
                        storagesAvailable: projectInfo.storagesAvailable
                    }, function() {
                        resolve(projectInfo.project);
                    });
                }

                that.repopulateFromSolarPanels();
            });
        });
    }


    initGeocodingForAddress(projectInfo, resolve) {
        if (window.appComponent.state.gmAPILoaded) {
            this.doGeocodingForAddress(projectInfo, resolve);
        } else {
            window.addEventListener('google-maps-loaded', () => this.doGeocodingForAddress(projectInfo, resolve));
        }
    }
    doGeocodingForAddress(projectInfo, resolve) {
        let that = this;

        this.geolocationReady = this.geolocateAddress(projectInfo.project.address);
        this.geolocationReady.then(function(coords) {
            if (coords.lat) {projectInfo.project.lat = coords.lat;}
            if (coords.lng) {projectInfo.project.lng = coords.lng;}

            that.setState({
                project: projectInfo.project,
                panelTypesAvailable: projectInfo.panelTypesAvailable,
                invertersAvailable: projectInfo.invertersAvailable,
                storagesAvailable: projectInfo.storagesAvailable
            }, function() {
                resolve(projectInfo.project);
            });
        });
    }


    geolocateAddress(address) {
        let that = this,
            geocoder = new google.maps.Geocoder();

        const geolocationReady = new Promise((resolve, reject) => {
            geocoder.geocode({address: address}, function (results, status) {
                if (status == google.maps.GeocoderStatus.OK) {
                    //console.log('Address geolocated');
                    resolve({
                        lat: results[0].geometry.location.lat(),
                        lng: results[0].geometry.location.lng()
                    });
                } else {
                    reject();
                }
            });
        });

        return geolocationReady;
    }


    includeGoogleMapsAPI() {
        const script = document.createElement("script");

        const params = {
            v: '3.exp',
            libraries: 'places',
            callback: 'googleMapsAPILoadedCallback',
            key: process.env.REACT_APP_GOOGLEMAPS_TOKEN
        };
        let paramsArr = [];
        for (const [key, value] of Object.entries(params)) {paramsArr.push(key+'='+value);}
        script.src = "https://maps.googleapis.com/maps/api/js?"+paramsArr.join('&');
        script.async = true;
        script.defer = true;

        document.body.appendChild(script);

        const callbackScript = document.createElement("script");
        callbackScript.innerHTML = 'function googleMapsAPILoadedCallback() {window.appComponent.googleMapsAPILoadedCallback();}';
        document.body.appendChild(callbackScript);
    }


    googleMapsAPILoadedCallback() {
        this.setState({
            gmAPILoaded: true
        }, () => {
            const event = new Event("google-maps-loaded");
            window.dispatchEvent(event);
        });
    }


    onNearmapPhotoChanged(value, bbox) {
        let states = {
            nearmapPhoto: value,
            nearmapBBox: bbox
        };
        if (this.state.project.mapType !== 'nearmap') {
            let project = {...this.state.project};
            project.mapType = 'nearmap';

            states['project'] = project;
        }

        this.setState(states);
    }


    bugReport(errorMessage) {
        if (process.env.NODE_ENV == 'production') {
            const errorHeader = '➡ '+window.location+"\r\n";
            errorMessage = errorHeader + errorMessage;

            //console.log(errorMessage);

            if (process.env.NODE_ENV == 'production') {
                this.apiHelper.bugReport(errorMessage);
            }

            this.setState({
                hasError: true
            });
            if (this.mapComponent.current) {
                this.mapComponent.current.hideLoader();
            }

            Error.redirectToErrorPage();
        }

        //throw new Error("Something went badly wrong!");
    }


    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }


    render() {
        if (this.state.hasError) {
            return (
                <Error />
            );
        }

        if (this.route != 'edit' && this.route != 'view' && this.route != 'request-design') {
            return (
                <Blank />
            );
        }

        return (
            <div className="cover">
                <PSAlert
                    open={this.state.alertOpen}
                    title="Delete existing design?"
                    description="You are about to replace existing address and erase current design. This action cannot be undone."
                    openChanged={this.onAlertOpenChanged}
                    onAccept={this.onAlertAccept}
                />

                <TopPanel
                    mapType={this.state.project.mapType}
                    address={this.state.project.address}
                    lat={this.state.project.lat}
                    lng={this.state.project.lng}
                    geolocationReady={this.geolocationReady}
                    panelType={this.state.project.panelType}
                    panelTypesAvailable={this.state.panelTypesAvailable}
                    inverter={this.state.project.inverter}
                    invertersAvailable={this.state.invertersAvailable}
                    storage={this.state.project.storage}
                    storagesAvailable={this.state.storagesAvailable}
                    isCalculating={this.state.isCalculating}
                    route={this.route}
                    panelTypeChangeAvailable={this.state.project.data === undefined || this.state.project.data === null || this.state.project.data.length === 0}
                    requestDesignStatus={this.state.requestDesignStatus}
                    nearmapStatus={this.state.project.nearmapStatus}

                    onChange={this.topPanelChanged}
                    onCalculate={this.onCalculate}
                    onShadeAnalysis={this.onShadeAnalysis}
                    onNearmapPhotoChanged={this.onNearmapPhotoChanged}
                />

                {this.route === 'edit' &&
                <OnmapPanel
                    route={this.route}
                    onmapProjectSummary={this.state.onmapProjectSummary}
                    onSolarOffsetChange={this.onSolarOffsetChange}
                    isIdle={this.state.onmapPanelIdle}
                />
                }

                <Map
                    ref={instance => { this.mapComponent = instance; }}

                    center={{lat: this.state.project.lat, lng: this.state.project.lng}}
                    mapType={this.state.project.mapType}
                    nearmapStatus={this.state.project.nearmapStatus}
                    panelType={this.state.project.panelType}
                    route={this.route}
                    projectInfoReady={this.projectInfoReady}
                    geolocationReady={this.geolocationReady}
                    nearmapPhoto={this.state.nearmapPhoto}
                    nearmapBBox={this.state.nearmapBBox}

                    showShadingAlert={this.onShadingAlertOpenChanged}
                    updateCenter={this.updateCenter}

                    onDataChanged={this.onDataChanged}
                />

                {this.routeSubtype === 'request-design' && (
                <BottomPanel
                    uid={this.projectUID}
                    routeSubtype={this.routeSubtype}
                    tac={this.state.project.tac}
                />)}

                <ToastContainer />
            </div>
        );
    }
}

export default App;