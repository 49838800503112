import React, { Component } from 'react';

import TPAddressField from './TPAddressField';
import TPPanelTypeField from './TPPanelTypeField';
import TPMapTypeField from './TPMapTypeField';

import './TopPanel.css';
import $ from "jquery";
import TPInverterField from "./TPInverterField";
import Loading from "../General/Loading";
import TPStorageField from "./TPStorageField";

class TopPanel extends Component {
    constructor(props) {
        super(props);

        let defaultInverterValue = props.inverter;
        if (!props.inverter && this.props.invertersAvailable) {
            for (let inverter of Object.values(this.props.invertersAvailable)) {
                if (!defaultInverterValue) {
                    defaultInverterValue = inverter.id;
                }
            }
        }

        let defaultStorageValue = props.storage;
        if (!props.storage && this.props.storagesAvailable) {
            for (let storage of Object.values(this.props.storagesAvailable)) {
                if (!defaultStorageValue) {
                    defaultStorageValue = storage.id;
                }
            }
        }

        this.state = {
            address: props.address,
            lat: props.lat,
            lng: props.lng,
            inverter: defaultInverterValue,
            storage: defaultStorageValue,
            panelType: props.panelType,
            panelTypeChangeAvailable: props.panelTypeChangeAvailable,
            mapType: props.mapType,
            isCalculating: props.isCalculating,
        };

        this.panelTypeChanged = this.panelTypeChanged.bind(this);
        this.inverterChanged = this.inverterChanged.bind(this);
        this.storageChanged = this.storageChanged.bind(this);
        this.addressChanged = this.addressChanged.bind(this);
        this.mapTypeChanged = this.mapTypeChanged.bind(this);
        this.calculate = this.calculate.bind(this);
        this.shadeAnalysis = this.shadeAnalysis.bind(this);
        this.isNotEditable = this.isNotEditable.bind(this);
    }

    addressChanged(value) {
        this.setState({
            address: value.address,
            lat: value.lat,
            lng: value.lng,
        }, () => {
            this.props.onChange({
                address: this.state.address,
                lat: this.state.lat,
                lng: this.state.lng
            });
        });
    }
    panelTypeChanged(value) {
        this.setState({
            panelType: value
        }, () => {
            this.props.onChange({
                panelType: this.state.panelType
            });
        });
    }
    inverterChanged(value) {
        this.setState({
            inverter: value
        }, () => {
            this.props.onChange({
                inverter: this.state.inverter
            });
        });
    }
    storageChanged(value) {
        this.setState({
            storage: value
        }, () => {
            this.props.onChange({
                storage: this.state.storage
            });
        });
    }
    mapTypeChanged(value) {
        this.setState({
            mapType: value
        }, () => {
            this.props.onChange({
                mapType: this.state.mapType
            });
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        let newState = {},
            key = ['address', 'lat', 'lng', 'panelType', 'panelTypeChangeAvailable', 'inverter', 'storage', 'mapType', 'isCalculating'];

        for (const k of key) {
            if (nextProps[k] !== this.state[k]) {
                newState[k] = nextProps[k];
            }
        }

        if (nextProps.mapType && JSON.stringify(newState) != '{}') {
            this.setState(newState);
        }
    }


    calculate(e) {
        e.preventDefault();

        /*setTimeout(() => {*/
        this.setState({
            isCalculating: true
        });
        $('.export').prop('disabled', true);
        this.props.onCalculate(e);
        /*}, 500, this, e);*/
    }


    shadeAnalysis(e) {
        e.preventDefault();

        this.props.onShadeAnalysis();
    }


    componentDidMount() {
        $('body').on('click', '.top-panel-btns .toggle-type', this.shadeAnalysis);
        $('body').on('touchend', '.top-panel-btns .toggle-type', this.shadeAnalysis);

        $('body').on('click', '.top-panel-btns .export', this.calculate);
        $('body').on('touchend', '.top-panel-btns .export', this.calculate);
    }


    isNotEditable() {
        return (this.props.route !== 'edit' || !this.state.mapType);
    }


    render() {
        return (
            <div className={'top-panel'+((this.isNotEditable()) ? (' d-none') : (''))}>
                <div className="top-panel-inputs">
                    {!this.isNotEditable() && (
                    <TPAddressField
                        address={this.state.address}
                        lat={this.props.lat}
                        lng={this.props.lng}
                        onChange={this.addressChanged}
                    />
                    )}

                    <TPPanelTypeField
                        value={this.state.panelType}
                        panelTypes={this.props.panelTypesAvailable}
                        changeAvailable={this.state.panelTypeChangeAvailable}
                        onChange={this.panelTypeChanged}
                    />

                    <TPInverterField
                        value={this.state.inverter}
                        inverters={this.props.invertersAvailable}
                        onChange={this.inverterChanged}
                    />

                    {/*
                    <TPStorageField
                        value={this.state.storage}
                        storages={this.props.storagesAvailable}
                        onChange={this.storageChanged}
                    />*/}

                    <TPMapTypeField
                        value={this.state.mapType}
                        nearmapStatus={this.props.nearmapStatus}
                        onChange={this.mapTypeChanged}
                        onNearmapPhotoChanged={this.props.onNearmapPhotoChanged}
                    />
                </div>

                <div className="top-panel-btns">
                    <div className="form-group">
                        <button className="btn btn-warning toggle-type">{
                            (window.location.hostname.indexOf('test') >= 0 || window.location.hostname.indexOf('localhost') >= 0)?('Heatmap'):('Shade Analysis')
                        }</button>
                    </div>

                    <div className="form-group text-end">
                        <button className="btn btn-outline-success active export" disabled={((this.props.invertersAvailable && (this.props.invertersAvailable.length == 0 || this.state.inverter == '' || !this.props.invertersAvailable[this.state.inverter]/* || this.props.invertersAvailable[this.state.inverter].is_active == 0*/))?('disabled'):(false))}>
                            <Loading visible={this.state.isCalculating} label="Calculate" />
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default TopPanel;
