import React, { Component } from 'react';

import './OnmapPanel.css';
import $ from "jquery";

class OnmapPanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            panelOpen: false
        };

        this.updateOnWindowResize = this.updateOnWindowResize.bind(this);
    }


    updateOnWindowResize(e) {
        let top = $('.map-panel').offset().top + 25;
        $('.onmap-panel').css({top: top});
    }


    componentDidMount() {
        let top = $('.map-panel').offset().top + 25;
        $('.onmap-panel').css({top: top});


        if (this.props.onmapProjectSummary.panelType !== this.props.onmapProjectSummary.panelType) {
            let DCACRatio = this.getDCACRatio();
            this.props.onSolarOffsetChange(DCACRatio);
        }

        window.addEventListener('resize', this.updateOnWindowResize);
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.onmapProjectSummary.panelType !== this.props.onmapProjectSummary.panelType) {
            let DCACRatio = this.getDCACRatio();
            this.props.onSolarOffsetChange(DCACRatio);
        }
    }


    numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


    getDCACRatio() {
        let DCACRatio = 1.2,
            moduleCount = 0;

        if (this.props.onmapProjectSummary.data && this.props.onmapProjectSummary.panelType) {
            for (const panelGroup of this.props.onmapProjectSummary.data) {
                moduleCount += panelGroup.panels.length;
            }

            if (
                window.appComponent.state.invertersAvailable &&
                window.appComponent.state.project.inverter &&
                window.appComponent.state.invertersAvailable[window.appComponent.state.project.inverter]
            ) {
                if (window.appComponent.state.invertersAvailable[window.appComponent.state.project.inverter]['is_micro_inverter']) {
                    DCACRatio = this.props.onmapProjectSummary.panelType.wattage / window.appComponent.state.invertersAvailable[window.appComponent.state.project.inverter]['rated_power_output'];
                } else {
                    DCACRatio = moduleCount * this.props.onmapProjectSummary.panelType.wattage / window.appComponent.state.invertersAvailable[window.appComponent.state.project.inverter]['rated_power_output'];
                }
            }
        }
        DCACRatio = 1.2; //TODO: hard coded default value for non-micro-inverters

        DCACRatio = Math.round(DCACRatio * 100) / 100;

        return DCACRatio;
    }


    getOffset() {
        let offset = 0,
            totalProduction = this.getTotalProduction();

        if (this.props.onmapProjectSummary.data) {
            offset = (this.props.onmapProjectSummary.tac?Math.round(totalProduction / this.props.onmapProjectSummary.tac * 100):null);
        }

        return offset;
    }


    getTotalProduction() {
        let totalProduction = 0;

        if (this.props.onmapProjectSummary.data) {
            for (const panelGroup of this.props.onmapProjectSummary.data) {
                totalProduction += parseFloat(panelGroup.production);
            }
        }

        return Math.round(totalProduction);
    }


    getModuleCount() {
        let moduleCount = 0;

        if (this.props.onmapProjectSummary.data) {
            for (const panelGroup of this.props.onmapProjectSummary.data) {
                moduleCount += panelGroup.panels.length;
            }
        }

        return moduleCount;
    }


    getSysSize() {
        let moduleCount = this.getModuleCount(),
            sysSize = 0;


        if (this.props.onmapProjectSummary.data && this.props.onmapProjectSummary.panelType) {
            sysSize = Math.round(moduleCount * this.props.onmapProjectSummary.panelType.wattage / 10) / 100;
        }

        sysSize = Math.round(sysSize * 100) / 100;

        return sysSize;
    }


    render() {
        if (this.props.route != 'edit') {
            return null;
        }

        const data = {
            moduleCount: this.getModuleCount(),
            sysSize: this.getSysSize(),
            totalProduction: this.getTotalProduction(),
            offset: this.getOffset(),
            DCACRatio: this.getDCACRatio(),
        };

        if ($('.map-panel').length && $('.onmap-panel').length) {
            let top = $('.map-panel').offset().top + 25;
            $('.onmap-panel').css({top: top});
        }

        let coverClass = 'onmap-panel';
        if (this.state.panelOpen) {coverClass += ' open';}
        if (this.props.isIdle) {coverClass += ' idle';}

        return (
            <div className={coverClass}>
                <div className="onmap-panel-group">
                    <div className="onmap-panel-item">
                        <h4>Size</h4>
                        <p>{((data.sysSize)?(data.sysSize+' kW'):("0"))}</p>
                    </div>

                    <div className="onmap-panel-item">
                        <h4>Offset</h4>
                        <p><span>{((data.offset)?(data.offset+'%'):(((data.totalProduction)?('0'):("N/A"))))}</span></p>
                    </div>

                    <button className={'collapser'} onClick={(e) => {this.setState({panelOpen: !this.state.panelOpen})}}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5"/>
                        </svg>
                    </button>
                </div>

                <div className="onmap-panel-group">
                    <div className="onmap-panel-item">
                        <h4>Est. Production</h4>
                        <p><span>{((data.totalProduction)?(this.numberWithCommas(data.totalProduction)+' kWh'):("N/A"))}</span></p>
                    </div>
                </div>

                <div className="onmap-panel-group">
                    <div className="onmap-panel-item">
                        <h4>DC/AC</h4>
                        <p className={((data.DCACRatio && (data.DCACRatio < 0.75 || data.DCACRatio > 1.33))?('highlighted'):(''))}>{((data.DCACRatio)?(data.DCACRatio):("0"))}</p>
                    </div>

                    <div className="onmap-panel-item">
                        <h4>Panel Count</h4>
                        <p>{data.moduleCount}</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default OnmapPanel;
