import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import * as turf from "@turf/turf";

import imgSetObstructions from './../../assets/img/obstructions.svg';
import setbacksBg from './../../assets/img/setbacks-bg-75.png';

import $ from "jquery";
import * as L from "leaflet";
import "./../../helpers/leaflet-polygon.fillPattern";

import Setbacks from "../../Setbacks/Setbacks";
import PSSlider from "../General/PSSlider";


class SetbackSettingsToolbar extends Component {
    constructor(props) {
        super(props);

        this.map = this.props.setbacks.map;
        this.props.setbacks.attachSettingsToolbar(this);

        this.defaults = {
            obstructionSize: 2,
            setbacksSize: 2
        };

        if (this.props.setbacks.loadedSetbacksSize) {
            this.defaults.setbacksSize = this.props.setbacks.loadedSetbacksSize;
        } else if (window.appComponent.state.project.defaultSetbacks > 0 && window.appComponent.state.project.defaultSetbacks <= 10) {
            this.defaults.setbacksSize = window.appComponent.state.project.defaultSetbacks;
        }

        this.state = {
            obstructionSize: this.defaults.obstructionSize,
            setbacksSize: this.defaults.setbacksSize,
            active: true,
            disabledSides: this.props.setbacks.loadedDisabledSides
        };

        this.setbacksMainLine = null;
        this.setbacksPolygon = null;

        this.drawSetbacks = this.drawSetbacks.bind(this);
        this.removeSetbacks = this.removeSetbacks.bind(this);
        this.toggleOnmapToolbar = this.toggleOnmapToolbar.bind(this);
        this.cut = this.cut.bind(this);
        this.resave = this.resave.bind(this);
        this.updateLayer = this.updateLayer.bind(this);
        this.putObstruction = this.putObstruction.bind(this);
        this.removeObstruction = this.removeObstruction.bind(this);
        this.onObsctuctionSizeChange = this.onObsctuctionSizeChange.bind(this);
        this.onSetbacksSizeChange = this.onSetbacksSizeChange.bind(this);
        this.deleteSetbacks = this.deleteSetbacks.bind(this);
        this.enableEdit = this.enableEdit.bind(this);
        this.changeHint = this.changeHint.bind(this);
        this.setbacksHovered = this.setbacksHovered.bind(this);
        this.hideHoverPolyline = this.hideHoverPolyline.bind(this);
        this.onSetbacksClick = this.onSetbacksClick.bind(this);
    }


    show(withResave) {
        if (withResave !== false) {
            this.resave();
        }

        this.setState({
            active: true
        });

        this.props.onDrawingChanged('setbacks');
    }


    hide() {
        this.setState({
            active: false
        });

        this.props.onDrawingChanged('');
    }


    /**
     * Add new group of solar panels
     */
    toggleOnmapToolbar(e) {
        e.preventDefault();
        this.props.onToggleOnmapToolbar();

        $(e.currentTarget).siblings('.toolbar-extending').find('.btn.active').removeClass('active');
        $(e.currentTarget).parent().siblings('.active').removeClass('active');
        $(e.currentTarget).parent().toggleClass('active');
    }


    /**
     * Add new group of solar panels
     */
    updateLayer(id, layer) {
        this.props.updateLayer(id, layer);
    }


    /**
     * Obstruction Size changed
     */
    onObsctuctionSizeChange(values) {
        this.setState({
            obstructionSize: values[0]
        });
    }


    /**
     * Setback size changed
     */
    onSetbacksSizeChange(values) {
        this.setState({
            setbacksSize: values[0]
        }, function() {
            this.resave();
        });
    }


    /**
     * Start drawing setbacks
     */
    putObstruction(e) {
        e.preventDefault();

        let latLngA = this.map.getCenter(),
            pointA = this.map.latLngToContainerPoint(latLngA),
            pointB = {x: pointA.x+1, y: pointA.y},
            latLngB = this.map.containerPointToLatLng(pointB),
            distanceAB = latLngA.distanceTo(latLngB) * 3.28084, // 1px in feet
            obstructionSizePX = Math.ceil(this.state.obstructionSize / distanceAB / 2) * 2; // in pixels
        ;

        let obstruction = new L.marker(this.map.getCenter(), {
            icon: new L.divIcon({
                iconSize: [obstructionSizePX, obstructionSizePX],
                iconAnchor: [obstructionSizePX/2, obstructionSizePX/2],
                className: 'obstruction-marker'
            }),
            draggable: true
        }).addTo(this.map);

        this.props.setbacks.obstructions.push(obstruction);
    }


    /**
     * Start drawing setbacks
     */
    removeObstruction(e) {
        e.preventDefault();

        $(e.currentTarget).siblings('.btn').removeClass('active');
        $(e.currentTarget).addClass('active');

        let setbacks = new Setbacks(this.map, this);
        this.setbacksGroups[setbacks.id] = setbacks;
    }


    /**
     * Start drawing setbacks
     */
    drawSetbacks(e) {
        e.preventDefault();

        $(e.currentTarget).siblings('.btn').removeClass('active');
        $(e.currentTarget).addClass('active');

        let setbacks = new Setbacks(this.map, this);
        this.setbacksGroups[setbacks.id] = setbacks;
    }


    /**
     * Remove active setbacks
     */
    removeSetbacks(e) {
        e.preventDefault();

        $(e.currentTarget).siblings('.btn').removeClass('active');
        $(e.currentTarget).addClass('active');

        this.map.pm.enableGlobalRemovalMode();
    }


    /**
     * Delete these setbacks
     */
    deleteSetbacks(e) {
        e.preventDefault();

        this.changeHint('');
        this.props.setbacks.selfDelete();
    }


    /**
     * Delete these setbacks
     */
    enableEdit(e) {
        e.preventDefault();

        this.props.setbacks.layer.pm.enable({
            allowSelfIntersection: false,
        });
    }


    getGeoJSONCoordinates(geoJSON) {
        if (!geoJSON || !geoJSON.geometry || !geoJSON.geometry.coordinates) {
            return [];
        }

        let results = [],
            rawCoordinates = geoJSON.geometry.coordinates;

        if (geoJSON.geometry.type === 'Polygon') {
            rawCoordinates = rawCoordinates[0];
        } else if (geoJSON.geometry.type === 'Point') {
            rawCoordinates = [rawCoordinates];
        }

        if (rawCoordinates.length) {
            for (const coords of rawCoordinates) {
                results.push(L.latLng(coords[1], coords[0]));
            }
        }

        return results;
    }


    getMiddleLatLngs(latLng1, latLng2) {
        const point1 = turf.point([latLng1.lng, latLng1.lat]),
            point2 = turf.point([latLng2.lng, latLng2.lat]),
            midpoint = turf.midpoint(point1, point2);
        return (this.getGeoJSONCoordinates(midpoint))[0];
    }


    getBearingByLatLngs(latLng1, latLng2) {
        return turf.bearing([latLng1.lng, latLng1.lat], [latLng2.lng, latLng2.lat])
    }


    getDestinationByLatLngs(originLatLngs, distance, bearing) {
        const coords = turf.destination([originLatLngs.lng, originLatLngs.lat], distance, bearing, {units: 'miles'});
        return (this.getGeoJSONCoordinates(coords))[0];
    }


    checkLatLngWithinPolygon(latLng, polygonCoordsLatLngs) {
        const points = turf.points([
            [latLng.lng, latLng.lat]
        ]);

        const polygonCoords = [];
        for (const polygonLatLngs of polygonCoordsLatLngs) {
            polygonCoords.push([polygonLatLngs.lng, polygonLatLngs.lat]);
        }
        const searchWithin = turf.polygon([polygonCoords]);

        let isWithin = false;
        const pointsWithin = turf.pointsWithinPolygon(points, searchWithin);
        if (pointsWithin.features.length) {
            isWithin = true;
        }

        return isWithin;
    }


    extendedLineEnd(latLng1, latLng2, extraDistance) {
        const bearing = this.getBearingByLatLngs(latLng1, latLng2),
            distance = turf.distance([latLng1.lng, latLng1.lat], [latLng2.lng, latLng2.lat], {units: 'miles'}) + extraDistance;
        return this.getDestinationByLatLngs(latLng1, distance, bearing);
    }


    offsetLine(latLng1, latLng2, allLatLngs) {
        const bearing = this.getBearingByLatLngs(latLng1, latLng2),
            midLatLng = this.getMiddleLatLngs(latLng1, latLng2),
            bearingLeft = bearing - 90,
            bearingRight = bearing + 90,
            distanceMiles = parseFloat(this.state.setbacksSize) * 12 / 63360,
            offsetMidLeft = this.getDestinationByLatLngs(midLatLng, distanceMiles, bearingLeft);

        let useLeftDirection = false;
        if (this.checkLatLngWithinPolygon(offsetMidLeft, allLatLngs)) {
            useLeftDirection = true;
        }

        let offsetStart, offsetEnd,
            extraDistance = parseFloat(this.state.setbacksSize) * 12 * 10 / 63360;
        if (useLeftDirection) {
            offsetStart = this.getDestinationByLatLngs(latLng1, distanceMiles, bearingLeft);
            offsetEnd = this.getDestinationByLatLngs(latLng2, distanceMiles, bearingLeft);
        } else {
            offsetStart = this.getDestinationByLatLngs(latLng1, distanceMiles, bearingRight);
            offsetEnd = this.getDestinationByLatLngs(latLng2, distanceMiles, bearingRight);
        }
        offsetStart = this.extendedLineEnd(offsetEnd, offsetStart, extraDistance);
        offsetEnd = this.extendedLineEnd(offsetStart, offsetEnd, extraDistance);

        return [offsetStart, offsetEnd];
    }


    getLinesIntersection(latLngs1, latLngs2) {
        let result = null;

        const line1 = turf.lineString([[latLngs1[0].lng, latLngs1[0].lat], [latLngs1[1].lng, latLngs1[1].lat]]),
            line2 = turf.lineString([[latLngs2[0].lng, latLngs2[0].lat], [latLngs2[1].lng, latLngs2[1].lat]]);

        let interPoint = turf.lineIntersect(line1, line2);
        if (interPoint.features.length) {
            result = (this.getGeoJSONCoordinates(interPoint.features[0]))[0];
        }

        return result;
    }


    createSetbacks(geoJSON) {
        if (this.setbacksMainLine) {this.setbacksMainLine.remove();}
        if (this.setbacksPolygon) {this.setbacksPolygon.remove();}
        if (this.setbacksPolygonPhantom) {this.setbacksPolygonPhantom.remove();}
        if (this.hoverPolyline) {this.hoverPolyline.setLatLngs([]);}
        if (this.hoverPolygon) {this.hoverPolygon.setLatLngs([]);}
        if (this.setbacksPolygonCoordsArrays) {this.setbacksPolygonCoordsArrays = null;}

        const coords = this.getGeoJSONCoordinates(geoJSON),
            distanceMiles = parseFloat(this.state.setbacksSize) * 12 / 63360,
            bufferGeoJSON = turf.buffer(geoJSON, (-1)*distanceMiles, {units: 'miles', steps: 20});

        this.setbacksMainLine = L.polyline(coords, {color: '#D3DAE3', weight: 2, zIndex: 1, pane: 'tilePane'}).addTo(this.map);


        /**
         * Get inner polygon(s) and create a coords arrays for MultiPolygon to for setbacks
         */
        let polygonCoords;
        this.bufferCoords = [];
        if (bufferGeoJSON && bufferGeoJSON.geometry.type === 'Polygon') {
            this.bufferCoords = [this.getGeoJSONCoordinates(bufferGeoJSON)];
            polygonCoords = [coords, this.bufferCoords[0]];
        } else if (bufferGeoJSON && bufferGeoJSON.geometry.type === 'MultiPolygon') {
            polygonCoords = [coords];
            if (bufferGeoJSON.geometry.coordinates.length) {
                for (const coordsArr of bufferGeoJSON.geometry.coordinates) {
                    let bufferCoords = [];
                    for (const coordsLngLat of coordsArr[0]) {
                        bufferCoords.push(L.latLng(coordsLngLat[1], coordsLngLat[0]));
                    }
                    if (bufferCoords.length > 2) {
                        polygonCoords.push(bufferCoords);
                        this.bufferCoords.push(bufferCoords);
                    }
                }
            }
        } else {polygonCoords = [coords];}

        this.setbacksPolygon = L.polygon(polygonCoords, {fill: 'url('+setbacksBg+')', fillColor: '#D13E0A', fillOpacity: 0.35, color: '#D13E0A', opacity: 0.75,  weight: 2, zIndex: 1, pane: 'tilePane'}).addTo(this.map);
        this.setbacksPolygonPhantom = L.polygon(polygonCoords, {fillColor: '#ffffff', fillOpacity: 0, weight: 0, pane: 'tilePane'}).addTo(this.map);
        this.setbacksPolygonPhantom.on('click', this.onSetbacksClick);
        this.setbacksPolygonPhantom.on('mousemove', this.setbacksHovered);
        if (!this.hoverPolygon) {
            this.hoverPolygon = L.polygon([], {color: '#ffffff', opacity: 0.7, weight: 0, pane: 'tilePane'}).addTo(this.map);
            this.hoverPolygon.on('click', this.onSetbacksClick);
            this.hoverPolygon.on('mouseout', this.hideHoverPolyline);
            this.hoverPolygon.bringToFront();
        }
        this.setbacksMainLine.bringToFront();

        if (this.state.disabledSides.length) {
            this.disableSides(this.state.disabledSides);
        }

        return true;
    }


    getSectionSetbackArea(latLngs) {
        const distanceMiles = parseFloat(this.state.setbacksSize) * 12 / 63360,
            bufferTurf = turf.buffer(L.polyline(latLngs).toGeoJSON(), distanceMiles*1.08, {units: 'miles', steps: 20});

        let indexFrom, indexTo;
        const vertices = this.setbacksMainLine.getLatLngs();
        for (let i=0; i<vertices.length-1; i++) {
            if (vertices[i].equals(latLngs[0])) {
                indexFrom = i;
            }
            if (vertices[i].equals(latLngs[1])) {
                indexTo = i;
            }
        }
        if (
            Math.abs(indexFrom - indexTo) !== 1 && indexFrom === 0 ||
            Math.abs(indexFrom - indexTo) === 1 && indexFrom > indexTo
        ) {
            let temp = indexTo; indexTo = indexFrom; indexFrom = temp;
        }

        const indexPrev = ((indexFrom === 0)?(vertices.length-2):(indexFrom-1)), indexNext = indexTo+1,
            latLngPrev = vertices[indexPrev], latLngFrom = vertices[indexFrom], latLngTo = vertices[indexTo], latLngNext = vertices[indexNext];
        let angleFrom = this.getBearingByLatLngs(latLngFrom, latLngTo) - this.getBearingByLatLngs(latLngFrom, latLngPrev),
            angleTo = this.getBearingByLatLngs(latLngTo, latLngFrom) - this.getBearingByLatLngs(latLngTo, latLngNext),
            bisectorFrom = this.getBearingByLatLngs(latLngFrom, latLngTo) - angleFrom / 2,
            bisectorTo = this.getBearingByLatLngs(latLngTo, latLngFrom) - angleTo / 2,
            bisectorFromA = this.getDestinationByLatLngs(latLngFrom, distanceMiles / 2, bisectorFrom),
            bisectorFromB = this.getDestinationByLatLngs(latLngFrom, distanceMiles / 2, bisectorFrom-180),
            bisectorToA = this.getDestinationByLatLngs(latLngTo, distanceMiles / 2, bisectorTo),
            bisectorToB = this.getDestinationByLatLngs(latLngTo, distanceMiles / 2, bisectorTo-180);

        let fromAisInside = this.checkLatLngWithinPolygon(bisectorFromA, vertices),
            toAisInside = this.checkLatLngWithinPolygon(bisectorToA, vertices);

        let fromBisector, toBisector;
        if (fromAisInside) {fromBisector = bisectorFromA;} else {fromBisector = bisectorFromB; angleFrom = 360 - Math.abs(angleFrom);}
        if (toAisInside) {toBisector = bisectorToA;} else {toBisector = bisectorToB; angleTo = 360 - Math.abs(angleTo);}

        let fromStartBearing, fromEndBearing, toStartBearing, toEndBearing,
            bearingFromTo = this.getBearingByLatLngs(latLngFrom, latLngTo),
            bearingFromBisector = this.getBearingByLatLngs(latLngFrom, fromBisector),
            bearingToFrom = this.getBearingByLatLngs(latLngTo, latLngFrom),
            bearingToBisector = this.getBearingByLatLngs(latLngTo, toBisector);
        if (bearingFromTo < 0) {bearingFromTo += 360;}
        if (bearingFromBisector < 0) {bearingFromBisector += 360;}
        if (bearingToFrom < 0) {bearingToFrom += 360;}
        if (bearingToBisector < 0) {bearingToBisector += 360;}

        if (Math.abs((bearingFromTo + Math.abs(angleFrom / 2)) % 360 - bearingFromBisector) < 1) {
            fromStartBearing = bearingFromTo;
            fromEndBearing = bearingFromBisector;
        } else {
            fromStartBearing = bearingFromBisector;
            fromEndBearing = bearingFromTo;
        }
        if (Math.abs((bearingToFrom + Math.abs(angleTo / 2)) % 360 - bearingToBisector) < 1) {
            toStartBearing = bearingToFrom;
            toEndBearing = bearingToBisector;
        } else {
            toStartBearing = bearingToBisector;
            toEndBearing = bearingToFrom;
        }

        let fromSector = turf.sector([latLngFrom.lng, latLngFrom.lat], 10, fromStartBearing, fromEndBearing, {units: 'miles'}),
            toSector = turf.sector([latLngTo.lng, latLngTo.lat], 10, toStartBearing, toEndBearing, {units: 'miles'});

        let intersection1 = turf.intersect(bufferTurf, fromSector),
            intersection2 = turf.intersect(intersection1, toSector);

        let mainPolygonGeoJSON = L.polygon(this.setbacksMainLine.getLatLngs()).toGeoJSON(),
            intersectionCorrected = turf.intersect(mainPolygonGeoJSON, intersection2);

        if (!intersectionCorrected) {

        }

        return this.getGeoJSONCoordinates(intersectionCorrected);
    }


    getClosestSideToLayerPoint(layerPoint) {
        const coords = this.map.layerPointToLatLng(this.setbacksMainLine.closestLayerPoint(layerPoint)),
            vertices = this.setbacksMainLine.getLatLngs();

        let vertexFrom, vertexTo;
        for (let i=1; i<vertices.length; i++) {
            const shortDistance = vertices[i].distanceTo(vertices[i-1]),
                longDistance = vertices[i-1].distanceTo(coords) + vertices[i].distanceTo(coords);
            if (longDistance - shortDistance < 0.1) {
                vertexFrom = vertices[i-1];
                vertexTo = vertices[i];
            }
        }

        return [vertexFrom, vertexTo];
    }


    setbacksHovered(e) {
        if (
            !this.state.active ||
            !this.setbacksMainLine
        ) {return;}

        if (!this.hoverPolyline) {
            this.hoverPolyline = L.polyline([], {color: '#D32F2F', weight: 0}).addTo(this.map);
        }

        const closestSideVertices = this.getClosestSideToLayerPoint(e.layerPoint);

        if (!this.hoverPolygon) {
            this.hoverPolygon = L.polygon([], {color: '#ffffff', opacity: 0.7, weight: 0}).addTo(this.map);
        }

        const oldHoverPolylineLatLngs = this.hoverPolyline.getLatLngs();
        if (oldHoverPolylineLatLngs != closestSideVertices) {
            this.hoverPolyline.setLatLngs(closestSideVertices);
            this.hoverPolyline.bringToFront();

            const hoverPolygonCoords = this.getSectionSetbackArea(closestSideVertices);
            this.hoverPolygon.setLatLngs(hoverPolygonCoords).bringToFront();
        }
    }


    hideHoverPolyline() {
        if (this.hoverPolyline) {this.hoverPolyline.setLatLngs([]).bringToBack();}
        if (this.hoverPolygon) {this.hoverPolygon.setLatLngs([]).bringToBack();}
    }


    onSetbacksClick(e) {
        L.DomEvent.stopPropagation(e);

        if (
            !this.state.active
        ) {this.activate(); return;}

        let sectionEdgeLatLngs, sectionCoords;

        if (this.hoverPolyline) {sectionEdgeLatLngs = this.hoverPolyline.getLatLngs();}
        if (this.hoverPolygon) {sectionCoords = this.hoverPolygon.getLatLngs();}

        if (!sectionCoords || !sectionCoords.length || !sectionCoords[0].length) {
            sectionEdgeLatLngs = this.getClosestSideToLayerPoint(e.layerPoint);
            sectionCoords = [this.getSectionSetbackArea(sectionEdgeLatLngs)];
        }

        this.toggleSectionNextToInternalPolygon(sectionCoords[0], sectionEdgeLatLngs);
    }


    toggleSectionNextToInternalPolygon(sectionCoords, sectionEdgeLatLngs, updateDisabledSides) {
        if (!this.setbacksPolygonCoordsArrays) {
            this.setbacksPolygonCoordsArrays = this.setbacksPolygon.getLatLngs();
        }


        /**
         * Update a list of disabled sides of the polygon
         */
        let newDisabledSides = [], disabledSideExists = false;
        for (let i=0; i<this.state.disabledSides.length; i++) {
            if (JSON.stringify(this.state.disabledSides[i]) !== JSON.stringify(sectionEdgeLatLngs)) {
                newDisabledSides.push(this.state.disabledSides[i]);
            } else {
                disabledSideExists = true;
            }
        }
        if (!disabledSideExists && sectionEdgeLatLngs.length) {newDisabledSides.push(sectionEdgeLatLngs);}


        /**
         * Merge / remove a hovered polygon from an internal polygon
         */
        let coords = this.setbacksPolygonCoordsArrays;

        if (!this.tmpBig) {
            this.tmpBig = L.polygon([]);
        } else {
            this.tmpBig.setLatLngs([]);
        }

        let newCoords = [], existing = false, curSection = L.polygon([]), curPart = L.polygon([]);
        for (let i=0; i<coords.length; i++) {
            if (JSON.stringify(coords[i]) !== JSON.stringify(sectionCoords)) {
                newCoords.push(coords[i]);
            } else {
                existing = true;
            }
        }
        if (!existing) {newCoords.push(sectionCoords);}
        this.setbacksPolygonCoordsArrays = newCoords;

        let bufferCoordsNew = JSON.parse(JSON.stringify(this.bufferCoords));
        for (let i=this.bufferCoords.length+1; i<newCoords.length; i++) {
            let foundClosestPart = false;
            for (let j=0; j<this.bufferCoords.length; j++) {
                if (foundClosestPart) {continue;}

                /**
                 * Check if [j]'s inner polygon touches [i]'s removed side
                 */
                //round newCoords[i] to 6 digits
                let newCoordsRounded = [];
                for (const coord of newCoords[i]) {
                    newCoordsRounded.push(L.latLng(coord.lat.toFixed(7), coord.lng.toFixed(7)));
                }
                //round this.bufferCoords[j] to 6 digits
                let bufferCoordsRounded = [];
                for (const coord of this.bufferCoords[j]) {
                    bufferCoordsRounded.push(L.latLng(coord.lat.toFixed(7), coord.lng.toFixed(7)));
                }
                curSection.setLatLngs(newCoordsRounded);
                curPart.setLatLngs(bufferCoordsRounded);
                let newPartGeoJSON = turf.union(curPart.toGeoJSON(7), curSection.toGeoJSON(7));
                if (newPartGeoJSON.geometry.type === 'Polygon') {
                    curPart.setLatLngs(bufferCoordsNew[j]);
                    newPartGeoJSON = turf.union(curPart.toGeoJSON(7), curSection.toGeoJSON(7));
                    bufferCoordsNew[j] = this.getGeoJSONCoordinates(newPartGeoJSON);
                    foundClosestPart = true;
                }
            }
        }
        let bigPolyGeoJSON;
        for (const bufferCoords of bufferCoordsNew) {
            if (!bigPolyGeoJSON) {bigPolyGeoJSON = L.polygon(bufferCoords).toGeoJSON(7); continue;}

            let curBufferPart = L.polygon(bufferCoords);
            bigPolyGeoJSON = turf.union(bigPolyGeoJSON, curBufferPart.toGeoJSON(7));
        }
        if (bigPolyGeoJSON.geometry.type === 'Polygon') {
            bufferCoordsNew = [this.getGeoJSONCoordinates(bigPolyGeoJSON)];
        } else if (bigPolyGeoJSON.geometry.type === 'MultiPolygon') {
            bufferCoordsNew = [];
            if (bigPolyGeoJSON.geometry.coordinates.length) {
                for (const coordsArr of bigPolyGeoJSON.geometry.coordinates) {
                    let bufferCoords = [];
                    for (const coordsLngLat of coordsArr[0]) {
                        bufferCoords.push(L.latLng(coordsLngLat[1], coordsLngLat[0]));
                    }
                    if (bufferCoords.length > 2) {
                        bufferCoordsNew.push(bufferCoords);
                    }
                }
            }
        } else {
            bufferCoordsNew = [];
        }

        let newCoordsMerged = [newCoords[0], ...bufferCoordsNew];
        this.setbacksPolygon.setLatLngs(newCoordsMerged);

        if (updateDisabledSides !== false) {
            this.setState({
                disabledSides: newDisabledSides
            });
        }

    }


    disableSides(disabledSides) {
        if (disabledSides) {
            for (let i=0; i<disabledSides.length; i++) {
                const setbackMainCoords = this.setbacksMainLine.getLatLngs();
                let sideFound = true;
                for (let k=0; k<disabledSides[i].length; k++) {
                    let coordFound = false;
                    for (let l=0; l<setbackMainCoords.length; l++) {
                        if (setbackMainCoords[l].equals(disabledSides[i][k])) {
                            coordFound = true;
                        }
                    }

                    sideFound *= coordFound;
                }

                if (sideFound) {
                    const sectionCoords = this.getSectionSetbackArea(disabledSides[i]);
                    if (sectionCoords[0].equals(sectionCoords[sectionCoords.length-1])) {
                        sectionCoords.splice(-1);
                    }
                    this.toggleSectionNextToInternalPolygon(sectionCoords, disabledSides[i], false);
                }
            }
        }
    }


    changeHint(text) {
        this.props.changeOnmapHint(text);
    }


    hideSetbacks() {
        if (this.setbacksMainLine) {this.setbacksMainLine.removeFrom(this.map);}
        if (this.setbacksPolygon) {this.setbacksPolygon.removeFrom(this.map);}
        if (this.setbacksPolygonPhantom) {this.setbacksPolygonPhantom.removeFrom(this.map);}
        if (this.hoverPolyline) {this.hoverPolyline.removeFrom(this.map);}
        if (this.hoverPolygon) {this.hoverPolygon.addTo(this.map);}
    }
    showSetbacks() {
        if (this.setbacksMainLine) {this.setbacksMainLine.addTo(this.map);}
        if (this.setbacksPolygon) {this.setbacksPolygon.addTo(this.map);}
        if (this.setbacksPolygonPhantom) {this.setbacksPolygonPhantom.addTo(this.map);}
        if (this.hoverPolyline) {this.hoverPolyline.addTo(this.map);}
        if (this.hoverPolygon) {this.hoverPolygon.addTo(this.map);}
    }
    activate() {
        this.props.setbacks.enable();
    }


    componentWillUnmount() {
        if (this.setbacksMainLine) {this.setbacksMainLine.remove(); this.setbacksMainLine = null;}
        if (this.setbacksPolygon) {this.setbacksPolygon.remove(); this.setbacksPolygon = null;}
        if (this.setbacksPolygonPhantom) {this.setbacksPolygonPhantom.removeFrom(this.map); this.setbacksPolygonPhantom = null;}
        if (this.hoverPolyline) {this.hoverPolyline.removeFrom(this.map); this.hoverPolyline = null;}
        if (this.hoverPolygon) {this.hoverPolygon.removeFrom(this.map); this.hoverPolygon = null;}
        if (this.setbacksPolygonCoordsArrays) {this.setbacksPolygonCoordsArrays = null;}

        this.map.pm.enableGlobalRemovalMode();
        this.changeHint('');
    }


    cut(e) {
        e.preventDefault();

        this.map.pm.enableGlobalCutMode({
            allowSelfIntersection: false,
            layersToCut: [this.props.setbacks.layer]
        });

        this.map.on('pm:cut', (e) => {
            this.updateLayer(this.props.setbacks.id, e.layer);
            this.map.pm.disableGlobalCutMode();


            let geoJSON = this.props.setbacks.layer.toGeoJSON();
            console.log(geoJSON);
        });
    }


    resave() {
        let geoJSON = this.props.setbacks.layer.toGeoJSON();
        this.props.setbacks.layer.remove();

        let setbacks = this.createSetbacks(geoJSON);
        this.changeHint('');
    }


    render() {
        const setbackEditMarkerSelector = '.tb-setbacks-change-'+this.props.setbacks.id,
            setbackEditMarker = document.querySelector(setbackEditMarkerSelector);

        return (
            <>
                {setbackEditMarker && ReactDOM.createPortal(
                    <div className="toolbar-extension toolbar-extending">
                        <PSSlider onChange={this.onSetbacksSizeChange} defaultValue={this.state.setbacksSize} value={this.state.setbacksSize} />
                        <div className="toolbar-slider-value">{this.state.setbacksSize}&nbsp;ft</div>
                    </div>,
                    setbackEditMarker
                )}
                <ul className={this.state.active?'onmap-toolbar':'onmap-toolbar d-none'} data-id={this.props.setbacks.id}>
                {/*<li className="d-none">
                    <a href="#" className="btn btn-default" onClick={this.toggleOnmapToolbar}><img src={imgSetTilt} /> <span>Set Tilt</span></a>

                    <div className="toolbar-extension">
                        <div className="toolbar-slider">
                            <div className="toolbar-slider-progress">
                                <div className="toolbar-slider-progress-caret"></div>
                            </div>
                        </div>
                        <div className="toolbar-slider-value">45&deg;</div>
                    </div>
                </li>
                <li>
                    <a href="#" className="btn btn-default" onClick={this.toggleOnmapToolbar}><img src={imgDrawSetbacks} /> <span>Change Setbacks</span></a>

                    <div className="btn-block toolbar-extending">
                        <a href="#" className="btn" onClick={this.enableEdit}>Edit</a>
                        <a href="#" className="btn" onClick={this.deleteSetbacks}>Remove</a>
                        <a href="#" className="btn" onClick={this.cut}>Cut</a>
                        <a href="#" className="btn" onClick={this.resave}>Resave</a>
                    </div>
                </li>
                <li>
                    <a href="#" className="btn btn-default" onClick={this.toggleOnmapToolbar}><img src={imgSetSetbacks} /> <span>Setback</span></a>

                    <div className="toolbar-extension toolbar-extending">
                        <PSSlider onChange={this.onSetbacksSizeChange} defaultValue={this.defaults.setbacksSize} />
                        <div className="toolbar-slider-value">{this.state.setbacksSize}&nbsp;ft</div>
                    </div>
                </li>*/}
                <li className="d-none">
                    <a href="#" className="btn btn-default" onClick={this.toggleOnmapToolbar}><img src={imgSetObstructions} /> <span>Obstructions</span></a>

                    <div className="toolbar-extending covering-block">
                        <div className="btn-block">
                            <a href="#" className="btn" onClick={this.putObstruction}>Draw</a>
                            <a href="#" className="btn" onClick={this.removeObstruction}>Remove</a>
                        </div>

                        <div className="toolbar-extension">
                            <PSSlider onChange={this.onObsctuctionSizeChange} defaultValue={this.defaults.obstructionSize} />
                            <div className="toolbar-slider-value">{this.state.obstructionSize}&nbsp;ft</div>
                        </div>
                    </div>
                </li>
            </ul>
            </>
        );
    }
}

export default SetbackSettingsToolbar;
